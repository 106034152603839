



























import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import sisSortableTable from '@/helper/sisSortableTable.vue';
import TableHead from '@/helper/TableHead';
import JwlButton from '@/components/JwlButton.vue';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

@Component({
  components: {
    JwlButton,
    sisSortableTable,
    CommonError,
    CommonIcon,
  },
})
export default class SisProgrammeList extends Vue {
  searchTerm = '';
  error = null;
  loaded = false;
  programmes: any[] = [];
  tableHeadDefinition = [
    new TableHead('general.code', 'key'),
    new TableHead('general.name', 'name'),
    new TableHead('programmeManagement.status', 'status', true, 'translation', 'programmeManagement.status_'),
    new TableHead('programmeManagement.startDate', 'startDate', true, 'date'),
    new TableHead('programmeManagement.endDate', 'endDate', true, 'date'),
    new TableHead(null, null, false, 'link', 'programmeOverview', 'programme'),
  ];

  mounted (): void {
    this.$store.dispatch('getData', 'programme/list')
      .then((data) => {
        this.programmes = data;
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
